@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .wysiwyg {
    @apply font-open;

    & * {
      @apply font-open;
    }

    & div {
      @apply w-full flex flex-col gap-3;
    }

    & p {
      @apply mb-4 text-md text-dark font-[400] text-pretty;
    }

    & p.has-text-align-center {
      @apply flex justify-center text-center;
    }

    & p.has-text-align-right {
      @apply flex justify-end text-right;
    }

    & p.has-text-align-left {
      @apply flex justify-start text-left;
    }

    & .wp-caption-text {
      @apply text-md italic;
    }

    & .wp-caption {
      @apply my-4 max-w-full;

      & img {
        @apply block mx-auto;
      }

      &.alignleft {
        @apply sm:float-left sm:mr-4 my-0;

        & img {
          @apply mx-0 sm:ml-0 sm:mx-auto;
        }
      }

      &.alignright {
        @apply sm:float-right sm:ml-4 my-0;

        & img {
          @apply mx-0 sm:mr-0 sm:mx-auto;
        }
      }

      &.aligncenter {
        @apply mx-auto;
      }
    }

    & a {
      @apply text-primary hover:underline;
    }
    & figure {
      @apply my-5 w-full rounded-2xl;

      & figcaption {
        @apply text-xs text-gray font-[400] font-open mt-2;
      }

      & img {
        @apply w-full h-auto rounded-md;

        &[width],
        &[style*="width"] {
          @apply w-[100%_!important];
        }
      }

      & div,
      & iframe {
        @apply w-full h-[400px] rounded-none;
      }

      &:has(img.small-img) {
        @apply shadow-none;
      }
    }

    & .wp-block-table {
      @apply overflow-auto;
    }

    & .table-wrapper {
      @apply relative overflow-x-auto shadow-md;
    }

    & table {
      @apply w-full text-sm text-left text-gray dark:text-gray;

      & tbody {
        & tr {
          @apply bg-white border-b text-base bg-slate-300;

          &:nth-child(odd) {
            @apply border-b bg-white-300 dark:bg-lightgray dark:border-black;
          }

          &:nth-child(even) {
            @apply border-b bg-white dark:bg-gray dark:border-gray;
          }

          &:nth-child(1) {
            @apply text-lg text-white bg-gray dark:bg-black dark:text-gray whitespace-nowrap font-bold;
          }

          & th,
          & td {
            @apply px-6 py-4;
          }
        }
      }

      &:has(thead) {
        & thead {
          @apply text-lg text-white bg-lightgray dark:text-gray whitespace-nowrap font-bold;

          & tr {
            & th {
              @apply px-6 py-3 whitespace-nowrap;
            }
          }

          & ~ tbody {
            & tr {
              &:nth-child(even) {
                @apply border-b text-white bg-white-300 dark:bg-lightgray dark:border-gray;
              }

              &:nth-child(odd) {
                @apply bg-ad text-gray dark:border-gray;
              }

              &:nth-child(1) {
                @apply text-base text-gray bg-white dark:bg-ad whitespace-normal font-normal font-open;
              }
            }
          }
        }
      }
    }

    & img.small-img {
      @apply w-[200px];
    }

    & div ~ div:has(video) {
      @apply grid grid-flow-row grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-4;
    }

    & h1 {
      @apply text-4xl font-bold text-slate-900 my-4 text-pretty;
    }

    & h2 {
      @apply text-3xl font-bold text-slate-900 text-pretty;
    }

    & h3 {
      @apply text-2xl font-bold text-slate-900 mb-2 text-pretty;
    }

    & h4,
    & h5,
    & h6 {
      @apply text-lg font-bold text-slate-900 mb-6 text-pretty;
    }

    & div.wp-block-columns {
      @apply flex-col lg:flex-row;
    }

    & ul {
      @apply list-disc list-inside mb-4 text-md text-dark font-[400] text-pretty;

      & li {
        @apply mb-2 text-md text-slate-900 font-[400] leading-relaxed text-pretty;
      }
    }

    & ol {
      @apply list-decimal list-inside mb-4 text-md text-dark font-[400]  text-pretty;

      & li {
        @apply mb-2 text-md text-slate-900 font-[400] leading-relaxed text-pretty;
      }
    }

    & strong {
      @apply font-bold;
    }

    /* gallery */

    & .wp-block-gallery {
      @apply flex items-center;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

/* font face */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gTD_u50.woff2")
    format("woff2");
}

/* header fixed  */
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans);

.artclose {
  height: 30px;
  width: 33px;
  cursor: pointer;
}

.burgx,
.burgx2,
.burgx3 {
  margin-top: 3px;
  width: 33px;
  height: 4px;
  position: absolute;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
}

.burgx2 {
  margin-top: 13px;
}
.burgx3 {
  margin-top: 23px;

  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

/* menu mobile  */

.menu-mobile {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  display: none;
  top: 82px;
  left: 0;
}
.menu-mobile.active {
  display: block;
}

.swiper.mySwiper {
  width: 90%;
}

/* slider center vertical */
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider-hero .button-prev::after {
  width: 15px;
  height: 15px;
  display: none;
}

.nav-tags .swiper-button-prev::after {
  width: 20px;
  height: 20px;
  display: none;
}
.nav-tags .swiper-button-next::after {
  width: 20px;
  height: 20px;
  display: none;
}

.nav-tags-cat .swiper-button-next::after {
  width: 20px;
  height: 20px;
  display: none;
}

.nav-tags-cat .swiper-button-next {
  box-shadow: -40px 0px 101px 60px rgba(255, 255, 255, 1);
}

.nav-tags .swiper-button-next {
  box-shadow: -40px 0px 101px 60px rgba(255, 255, 255, 1);
}

/* change arrow icon from button prev and next */
.nav-tags-cat .swiper-button-prev::after {
  width: 20px;
  height: 20px;
  display: none;
}

.mySwiperCat .swiper-slide {
  margin: 0; /* Ensure no margin */
  padding: 0; /* Ensure no padding */
}

.mySwiper .swiper-slide {
  margin: 0; /* Ensure no margin */
  padding: 0; /* Ensure no padding */
}

.slider-hero .button-next::after {
  width: 15px;
  height: 15px;
  display: none;
}

/* Add opacity to swiper-hero element that is not active */
.slider-hero .swiper-slide:not(.swiper-slide-active) {
  opacity: 0.7;
}

/* news display */
.news-display {
  width: 70%;
  height: 40vh;
  /* center horizontal absolute*/
  left: 50%;
  transform: translateX(-50%);
  top: 120px;
  background-color: #fff;
  z-index: 1001;
  opacity: 0;
  z-index: -1;
  transition: all 0.5s;
}

.news-display.show {
  opacity: 1;
  z-index: 1001;
}

/* placeholder */
#simple-search::placeholder {
  color: #000;
  /* font family montserrat */
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

#vertical_nav li button.active {
  @apply bg-primary/[.08] border-primary border-b-[1px] font-[700] text-primary;
}

.highlight-text {
  @apply text-primary font-[700];
}

html {
  scroll-behavior: smooth;
}

/* Main Styles for has-childrens */
.has-childrens > a {
  @apply relative pr-6 border-none; /* padding-right: 1.5rem and remove border */
}

.has-childrens > a:before {
  content: "";
  @apply absolute right-0 w-2.5 h-2.5 border-r-2 border-b-2 transition-transform duration-300 ease-in-out;
  top: 45%; /* Custom positioning */
  transform: translateY(-50%) rotate(45deg); /* Offset and rotate */
  border-color: currentColor;
}

.header__bottom__nav--second-level {
  @apply flex flex-col absolute z-20 top-full min-w-[180px] overflow-hidden invisible bg-[#f2f2f2] w-[calc(100%+80px)];
  transform-origin: top; /* Set origin for scale effect */
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
  opacity: 0; /* Initially hidden */
  transform: scaleY(0); /* Initially collapsed */
}

.menu-mobile .header__bottom__nav--second-level {
  @apply bg-[#272323] min-w-[280px] top-[calc(100%+20px)];
}

.menu-mobile .header__bottom__nav--second-level > li.current-menu-item > a {
  @apply bg-transparent text-white font-semibold;
}

.menu-desk .header__bottom__nav--second-level > li.current-menu-item > a {
  @apply bg-transparent text-primary font-medium;
}

.header__bottom__nav--second-level > li.current-menu-item {
  @apply border-l-2 border-primary;
}

.has-childrens {
  @apply relative;
}

.has-childrens:hover ul.header__bottom__nav--second-level {
  @apply visible scale-y-100 opacity-100 z-[99] max-h-[300px] overflow-y-auto;
  transform: scaleY(1); /* Expand vertically */
}

ul.header__bottom__nav--second-level li {
  @apply px-4 py-3 border-l-2 border-transparent;
}

ul.header__bottom__nav--second-level li a:hover {
  @apply border-none;
}

ul.header__bottom__nav--second-level li:hover {
  @apply transition-all ease-out duration-500 bg-lightgray border-l-2 border-primary;
}

/* loader */
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #ccc;
  /* Changed from red to a lighter color */
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

/* Your existing keyframes remain the same */
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Add this to prevent content flash */
body {
  visibility: visible !important;
}
